import React, {MouseEventHandler} from 'react'

import './social_item.css'

interface Props {
  item: {imgUrl: string, title: string, price: string, oldPrice: string, description: string, destinationName: string}
  buttonText: string
  onBookingClick: MouseEventHandler | undefined
}
export const SocialItem = (props: Props) => {
  const {item, buttonText, onBookingClick} = props
  const {imgUrl, title, price, oldPrice, description, destinationName} = item
  return (
    <div className="result-card">
      <img src={imgUrl} alt="#"/>
        <div className="card-content">
          <h2>{title}</h2>
          <p className="price">{price} {oldPrice && <span className="old-price">{oldPrice}</span>}</p>
          <p className="description">{description}</p>
          <p className="destination">{destinationName}</p>
          <button
            className="cta-btn"
            onClick={onBookingClick}
          >
            {buttonText}
          </button>
        </div>
    </div>
  )
}