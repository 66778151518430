import React from 'react'
import './index.css'

interface Props {
  color?: string
  onClick: () => void
}

export const CartButton = (props: Props) => {
  
  const {color, onClick} = props
  
  return (
    <button
      className="cart-button"
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        id="icon-cart"
        className="icon-cart"
        width="27" height="23" viewBox="0 0 27 23" style={{fill: color || '#007bff'}}
      >
        <path d="M24.47,16.68H7.12l-3.74-14H.08V.21H5.42l3.74,14H22.31l1.58-8.48H11.25V3.22H26.92Z"/>
        <path d="M23,20.59a2.2,2.2,0,1,0-2.2,2.2A2.19,2.19,0,0,0,23,20.59Z"/>
        <path d="M12.19,20.59A2.2,2.2,0,1,0,10,22.79,2.19,2.19,0,0,0,12.19,20.59Z"/>
      </svg>
    </button>
  )
}