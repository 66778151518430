import React from 'react'
import {MenuItem, SelectChangeEvent} from '@mui/material'
import {SelectInputLabel} from '../../../../components/SelectInputLabel/SelectInputLabel'
import './header.css'
import {CartButton} from '../../../../components/CartButton/CartButton'

interface Props {
  loading: boolean
  imgUrl: string
  imgAlt: string
  title: string
  
  destinationValue: string[]
  destinationOptions: {name: string, value: string}[]
  
  categoryValue: string[]
  categoryOptions: {name: string, value: string}[]
  
  tagValue: string[]
  tagOptions: {name: string, value: string}[]
  
  onChange:  (event: SelectChangeEvent , child?: Object) => void
  onIconClick
}

export const Header = (props: Props) => {
  const {imgUrl, imgAlt, title, destinationOptions, categoryOptions, tagOptions, destinationValue, categoryValue, tagValue, onChange, onIconClick} = props
      
  return (
    <header>
      <div className="logo">
        <img src={imgUrl} alt={imgAlt}/>
        <h1 className="text-provider">{title}</h1>
      </div>
      
      <CartButton
        onClick={onIconClick}
      />

      <div className="filters">
        <SelectInputLabel
          labelId={'destinationLabel'}
          label={'destination'}
          name={'destination'}
          id={'destination'}
          value={destinationValue}
          options={destinationOptions}
          multiple
          itemOption={(item) => (<MenuItem className="MuiMenuItem" key={'destination_' + item.value} value={item.value}>{item.name}</MenuItem>)}
          onChange={onChange}
        />

        <SelectInputLabel
          labelId={'categoryLabel'}
          label={'category'}
          name={'category'}
          id={'category'}
          value={categoryValue}
          options={categoryOptions}
          multiple
          itemOption={(item) => (<MenuItem className="MuiMenuItem" key={'category_' + item.value} value={item.value}>{item.name}</MenuItem>)}
          onChange={onChange}
        />
        
        <SelectInputLabel
          labelId={'tagLabel'}
          label={'tag'}
          name={'tag'}
          id={'tag'}
          value={tagValue}
          options={tagOptions}
          multiple
          itemOption={(item) => (<MenuItem className="MuiMenuItem" key={'tag_' + item.value} value={item.value}>{item.name}</MenuItem>)}
          onChange={onChange}
        />
        
      </div>
    </header>
  )
}