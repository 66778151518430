interface Props {
  t
  mainTag?: string
  href?: string
}

export const CtaView = ({t, mainTag, href}: Props) => {

  return (
        <div className="book-online-cta">
            <h2>{t("bookingOnline")}</h2> 
            <ul>

                <li>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        id="icon-clock"
                        className="icon-clock"
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                    >
                        <path d="M6.47,0a6.47,6.47,0,1,0,6.47,6.47h0A6.47,6.47,0,0,0,6.47,0m0,11.5a5,5,0,1,1,5-5,5,5,0,0,1-5,5m2.16-5H7.19V3.59a.72.72,0,1,0-1.44,0v3.6a.72.72,0,0,0,.72.72H8.63a.72.72,0,0,0,0-1.44"/>
                    </svg> 
                    {t("availability")}
                </li>

                <li>
                    <svg
                        xmlns="http://www.w3.org/2000/svg" aria-hidden="true" id="icon-bolt"
                        className="icon-bolt"
                        width="9"
                        height="14"
                        viewBox="0 0 9 14"
                    >
                        <path d="M8.88,5.91a.88.88,0,0,0-.79-.39H6V.82A.77.77,0,0,0,5.53.05a.8.8,0,0,0-.85.21l-4.57,7a.88.88,0,0,0,0,.87.88.88,0,0,0,.79.39H3l.12,4.7a.78.78,0,0,0,.45.77.86.86,0,0,0,.27.05.83.83,0,0,0,.58-.26l4.42-6.9,0-.06A.88.88,0,0,0,8.88,5.91Z"/>
                    </svg>
                    {t("confirmation")}
                </li>
                {mainTag && !href && 
                    <li>
      
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="256" height="256" viewBox="0 0 100 100">
                            <path
                              d="M 45 90 c -1.415 0 -2.725 -0.748 -3.444 -1.966 l -4.385 -7.417 C 28.167 65.396 19.664 51.02 16.759 45.189 c -2.112 -4.331 -3.175 -8.955 -3.175 -13.773 C 13.584 14.093 27.677 0 45 0 c 17.323 0 31.416 14.093 31.416 31.416 c 0 4.815 -1.063 9.438 -3.157 13.741 c -0.025 0.052 -0.053 0.104 -0.08 0.155 c -2.961 5.909 -11.41 20.193 -20.353 35.309 l -4.382 7.413 C 47.725 89.252 46.415 90 45 90 z"
                            />
                            <path
                              d="M 45 45.678 c -8.474 0 -15.369 -6.894 -15.369 -15.368 S 36.526 14.941 45 14.941 c 8.474 0 15.368 6.895 15.368 15.369 S 53.474 45.678 45 45.678 z"
                            />
                        </svg>
                            {mainTag}
                    </li>
                }
              
                {mainTag && href && <a href={href} target='_blank'>
                    <li>
      
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="256" height="256" viewBox="0 0 100 100">
                            <path
                              d="M 45 90 c -1.415 0 -2.725 -0.748 -3.444 -1.966 l -4.385 -7.417 C 28.167 65.396 19.664 51.02 16.759 45.189 c -2.112 -4.331 -3.175 -8.955 -3.175 -13.773 C 13.584 14.093 27.677 0 45 0 c 17.323 0 31.416 14.093 31.416 31.416 c 0 4.815 -1.063 9.438 -3.157 13.741 c -0.025 0.052 -0.053 0.104 -0.08 0.155 c -2.961 5.909 -11.41 20.193 -20.353 35.309 l -4.382 7.413 C 47.725 89.252 46.415 90 45 90 z"
                            />
                            <path
                              d="M 45 45.678 c -8.474 0 -15.369 -6.894 -15.369 -15.368 S 36.526 14.941 45 14.941 c 8.474 0 15.368 6.895 15.368 15.369 S 53.474 45.678 45 45.678 z"
                            />
                        </svg>
                            {mainTag}
                    </li>
                </a>}
                
            </ul>
        </div>
    )
}
