import React, {Component} from "react"
import FullCalendar, {DayCellContentArg, ViewApi } from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import {IHora} from '../models/IHora'
import './CalendarAvailable.css'
import Stretch from '../models/Stretch'
import {DisabledDay, getHoraIdaHigher} from '../helpers/DisabledDay'
import {horasIdaAvailableBefore, IMaxBeforeReserve} from '../Modules/Util'

interface Props {
    disabledDays
    horasIda
    idioma
    clickDateHandler: any
    dateSelected?
    dateMaxToReserve?: Date
    valueMaxToReserve?
    typeMaxToReserve?
    valueMaxBeforeReserve: number
    typeMaxBeforeReserve: string
    stretch: Stretch
    selectedHourMinutes: IHora
    handleDayRender?(info: {
        view: ViewApi
        date: Date
        allDay?: boolean
        el: HTMLElement
    }): void
}

export default class CalendarAvailable extends Component<Props, object> {

    pRef = React.createRef()

    handleDayCellClassNames = (dayCellContent: DayCellContentArg) => {
        
        const {date, isPast, isToday} = dayCellContent
        const {stretch, selectedHourMinutes, disabledDays, horasIda, dateMaxToReserve, valueMaxToReserve, typeMaxToReserve, valueMaxBeforeReserve, typeMaxBeforeReserve} = this.props

        //let dateMaxToReserve = new Date('2023-05-07')
        // let valueMaxToReserve = 3
        //let typeMaxToReserve = 'byDays'

        const disabledDay =  disabledDays?.find(value => value.day === date.getDate() && value.month === date.getMonth() && value.year === date.getFullYear())
    
        date.setHours(23, 59, 59, 999)
        let className = []
               
        if (isPast) {
            className.push('color-isPast')        
        }
        let today = new Date()
        
        const isMaxToReserve = DisabledDay.maxToReserve(
          date, typeMaxToReserve, valueMaxToReserve, dateMaxToReserve
        )

        if (isMaxToReserve) {
            className.push('color-isMax')
        }
        
        const hourMax = getHoraIdaHigher(stretch)
        
        const hoursMinutes = hourMax
        
        const disabledBefore = DisabledDay.maxBeforeReserve(
          date, typeMaxBeforeReserve, valueMaxBeforeReserve, stretch.tramoHora, hoursMinutes
        )
        
        const dataMaxBeforeReserve: IMaxBeforeReserve = {
            date,
            isTramoHora: stretch.tramoHora,
            valueMaxBeforeReserve: stretch.valueMaxBeforeReserve,
            typeMaxBeforeReserve: stretch.typeMaxBeforeReserve
        }
        const partialDisabledBefore = horasIdaAvailableBefore(stretch.horasIda, dataMaxBeforeReserve).length !== stretch.horasIda.length
        
        if (disabledBefore || partialDisabledBefore) {
            className.push('color-isMax')
        }

        if (isToday || !isPast) {
            if(className.length === 0) { // no está deshabilitado por fecha anterior ni por fecha máxima para reservar
                if (disabledDay && disabledDay.hours && disabledDay.hours.length>0){
                    const hoursFiltered = filterHours(horasIda, disabledDay.hours)             
                    if (hoursFiltered.length === horasIda.length) {
                        className.push('color-isDisabled')   
                    } else {
                        className.push('color-isPartialDisabled')  
                    }       
                } else if(disabledDay && disabledDay.hours && disabledDay.hours.length === 0){
                    className.push('color-isDisabled')                    
                }else {
                    className.push('day-cell-enabled-background')
                }          
            } else {
                if (disabledBefore) {
                    className.push('color-isDisabled')
                } else if (partialDisabledBefore) {
                    className.push('color-isPartialDisabled')
                }
            }
        }
       
        return className
    }
    
    render() {
     
        const {clickDateHandler, idioma, dateSelected} = this.props
        
        return (
            <>
                <FullCalendar
                    dayCellClassNames={this.handleDayCellClassNames}
                    firstDay={1}
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView={"dayGridMonth"}
                    dateClick={clickDateHandler}
                    selectable={true}
                    locale={idioma}
                    buttonText={{ today: idioma === 'es' ? 'Mes actual' : 'Current month' }}
                    contentHeight={'auto'}
                />
            </>
        )
    }
}

/*
  método para filtar las horas deshabilitadas en un IDay.hours ya que se puede dar el caso que alguna se haya eliminado
  en el servicio. Las que no estén en horasIda se eliminan
   */
export const filterHours = (horasIda: IHora[], hoursSelected: IHora[]) => {
    const result = hoursSelected.reduce((previous, hora) => {
        if (horasIda?.find((horaIda) => horaIda.hours === hora.hours && horaIda.minutes === hora.minutes)) {
            previous.push(hora)
        }
        return previous
    }, [])
    return result
}