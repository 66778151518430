import React, {Component} from 'react'
import {Header} from '../components/Header/Header'
import {Footer} from '../components/Footer/Footer'
import {SocialItem} from '../components/Item/SocialItem'
import './main-view.css'
import {Spinner} from 'reactstrap'
import {SelectChangeEvent} from '@mui/material'
import {withTranslation} from "react-i18next"

interface Props {
  t?
  loading: boolean
  imgUrl: string
  imgAlt: string
  title: string
  destinationValue: string[]
  destinationOptions: {name: string, value: string}[]
  categoryValue: string[]
  categoryOptions: {name: string, value: string}[]
  tagValue: string[]
  tagOptions: {name: string, value: string}[]
  items: any[]
  onChange: (event: SelectChangeEvent , child?: Object) => void
  
  onIconClick
  onBookingClick: (e, item) => void
}

const MainView = withTranslation('global')(class MainViewComponent extends Component<Props, object> {
  
  render() {
    const {t, items, loading, onBookingClick, ...props} = this.props
    
    if (loading) {
      return (
        <header>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              width: '100%'
            }}
          >
            <Spinner
              style={{
                color: '#007bff',
                width: 50,
                height: 50,
              }}
            />
          </div>
        </header>
      )
    }
    
    return (
      <>
        <Header loading={loading} {...props}/>

        {!loading && <div className="results-container">
          {items.map(item => {
            let key
            if (item.type === 'cs') {
              key = `social_item_${item.title}_${item.stretch_id}_${item.operator_id}`
            } else {
              key = `social_item_${item.title}_${item.stretch_id}`
            }
            return (
              <SocialItem
                key={key}
                item={item}
                buttonText={t('bookNow')}
                onBookingClick={(e) => onBookingClick(e, item)}
              />
            )
          })}
        </div>}

        {!loading && <Footer hrefSrc={[]}/>}
      </>
    )
  }
})

export default MainView