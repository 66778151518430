import React, {useState, useEffect, useRef} from 'react';
import SeatPicker from "react-seat-picker";
import Moment from 'moment';
// import '../styles/style.css';
import {useHistory} from 'react-router';
import {RouteComponentProps} from 'react-router';
import {
  addCollaboratorStretchToCart,
  getCollaboratorStretchData,
  getCollaboratorStretchesData,
  ICollaboratorStretch
} from './Request'
import {
  AddCallBackType,
  RemoveCallBackType,
  addVisitanteGlobal,
  generateTokenticket,
  mountPayloadStretch,
  quitarVisitanteGlobal,
  visOK,
  horasIdaAvailable,
  addExtraGlobal,
  quitarExtraGlobal, IMaxBeforeReserve, horasIdaAvailableBefore
} from '../Util'
import {TypeTicket} from '../../models/TypetTicket'
import {HeaderView} from '../../components/HeaderView'
import {addDiscount, IDiscount, resetDiscount, Vis} from '../../models/Vis'
import CollaboratorStretch from '../../models/CollaboratorStretch'
import {ISeat} from '../../models/ISeat'
import {IHora} from '../../models/IHora'
import {FooterView} from 'components/FooterView';
import {PricesView} from 'components/PricesView';
import {getChannels} from 'Request/Request';
import {Carousel} from "react-responsive-carousel"
import {InfoWeb} from 'components/InfoWeb'
import {modalError, modalInfo} from 'Modules/Payment/Request';
import Spinner from 'reactstrap/es/Spinner';
import CalendarAvailable from 'components/CalendarAvailable';
import * as Sentry from "@sentry/react";
import {Extras} from 'components/Extras';
import Swal from 'sweetalert2';
import {useInitIdioma} from 'customHooks/useInitIdioma';
import {TourListLoading} from 'components/TourListLoading';
import {CtaView} from 'components/CtaView';
import {DialogNoDate} from 'components/DialogNoDate';
import {OrigenDestino} from 'components/OrigenDestino';
import {SelectIdaVuelta} from 'components/SelectIdaVuelta';
import {BodyError} from 'components/bodyError';
import {Money} from '../../models/Money'
import queryString from 'query-string'
import {Request} from '../Afiliate/Request'
import {AxiosInstance, AxiosResponse} from 'axios'
import {ViewApi} from '@fullcalendar/react'
import {DisabledDay} from '../../helpers/DisabledDay'
import {LanguageButtons} from '../../components/LanguageButtons'
import {applyCoupon} from '../Stretch/Request'
import {CodeDiscountView} from '../../components/CodeDiscountView'

interface ParamsProps {
  stretch_id: string
  operator_id: string
  public: string
}

type Props = RouteComponentProps<ParamsProps>;

export const CollaboratorStretchPage: React.FC<Props> = (props: Props) => {

  const stretchID = Number(props.match.params.stretch_id)
  const operator_id = Number(props.match.params.operator_id)
  const publicToken = props.match.params.public;
  const history = useHistory();
  const {idioma, T, t, i18n} = useInitIdioma(publicToken) // Custom Hook

  const init: Vis = {
    names: [],
    indices: [],
    vis: [],
    price: [],
    base: 0.00,
    total: 0.00,
    totalNet: 0,
    totalDiscount: 0.00,
    extras: [],
    extrasEdit: {},
    disabledButtons: false,
    discount: null
  };
  const [passengerCategories, setPassengerCategories] = useState<any>([])

  const CHECKED_CLASSNAME = "btn btn-primary";
  const UNCHECKED_CLASSNAME = "btn btn-outline-primary";

  const [trayecto, setTrayecto] = useState<CollaboratorStretch>();

  const [openDatePopup, setOpenDatePopup] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [visitas, setVisitas] = useState<Vis>(init);
  const [quantityExtras, setQuantityExtras] = useState<any>()
  const [, setDD] = useState<Object[]>([]);
  const [dateClicked, setDateClicked] = useState<string>("");
  const [selectedHour, setSelectedHour] = useState<IHora>();
  const [totalSeats, setTotalSeats] = useState<number>(0); // Número de pasajeros totales
  const [disabled, setDisabled] = useState<boolean>(false);
  const [trayectoIdaVuelta, setTrayectoIdaVuelta] = useState<boolean>();
  const [soloIdaClassName, setSoloIdaClassName] = useState<string>(CHECKED_CLASSNAME);
  const [idaVueltaClassName, setIdaVueltaClassName] = useState<string>(UNCHECKED_CLASSNAME);
  const [channels, setChannels] = useState<any>();
  const [disabledCanal, setDisabledCanal] = useState<boolean>(false)
  const [adding, setAdding] = useState<boolean>(undefined);
  const [limiteCapacidad, setLimiteCapacidad] = useState<boolean>(false)
  const [minimumPassengerReach, setMinimumPassengerReach] = useState<boolean>(true)
  const [horasDisponibles, setHorasDisponibles] = useState([])
  const [monedasGreenpay, setMonedasGreenpay] = useState<any>();
  const [prices, setPrices] = useState<any>()
  const [sentidoOrigen, setSentidoOrigen] = useState<boolean>(true) // Determina si el sentido es del origen del trayecto al destino o sale del destino al origen
  const [currencyCredomatic, setCurrencyCredomatic] = useState<string>()
  const [a, setA] = useState<any>()
  const [language, setLanguage] = useState<string>(idioma)
  const [languages, setLanguages] = useState<string[]>()
  const [loadingCouponCode, setLoadintCouponCode] = useState<boolean>(false)
  const [couponMessage, setCouponMessage] = useState<string>()
  const [couponMessageCode, setCouponMessageCode] = useState<number>()
  
  let description = useRef('');
  let amount = 0;
  let canalSi = false;


  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])
  
  useEffect(() => {
    const parsed = queryString.parse(window.location.search)
    if (parsed?.at) {
      Request.getAfiliate(publicToken, parsed.at)
        .then(value => {
          // console.log('getAfiliate - value: ', value)
          setA(value)
        })
        .catch(reason => {
          modalError(
            reason.code,
            'Contacte con el administrador de la página. Gracias y disculpe las molestias',
            () => {
              history.push(`/${publicToken}/error/${reason.code}?at=${parsed.at}`)
            }
          )
        })
    }
  }, [])
  useEffect(() => {
    const querystring = window.location.search

    const params = new URLSearchParams(querystring)
    try {
      if (params.has('uuid_dmn')) {
        let uuid_dmn = params.get('uuid_dmn')
        localStorage.setItem('uuid_dmn', uuid_dmn)
      } else {
        localStorage.setItem('uuid_dmn', 'dmn')
      }
    } catch (e) {
      if (publicToken) {
        history.push(`/${publicToken}/cookies`)
      }
    }
  }, [])

  // efecto para recoger el trayecto
  useEffect(() => {
    i18n.changeLanguage(idioma);
    if (!trayecto) {
      getCollaboratorStretchData(publicToken, operator_id, stretchID)
        .then((result) => {
          if (currencyCredomatic) {
            if (currencyCredomatic !== result.primaryMoney.alphabeticCode) {
              setDisabledCanal(true)
            }
          }
          setTrayecto(result.cs)
        }).catch(e => {
        const scope = new Sentry.Scope();
        scope.setTag("request", "getCollaboratorStretchData");
        Sentry.captureException(e, scope);
        console.log('e: ', e)
        history.push(`/${publicToken}/error/Ha ocurrido un error - getCollaboratorStretchesData`)
      })
    }
  }, [])

  useEffect(() => {
    if (trayecto) {
      const entries = Object.entries(trayecto.dataweb?.array?.custom)

      const languages = entries?.reduce((old, current, index) => {
        if (current[1] !== '') {
          old.push(current[0])
        }
        return old
      }, [])
        .sort()

      setLanguages(languages)
      setTrayectoIdaVuelta(false) // por defecto a false
    }
  }, [trayecto])

  // efecto para recoger los canales
  useEffect(() => {
    if (trayecto && !channels) {
      getChannels(publicToken).then((res) => {
        let channels
        if (res.msg === 'No TMT') {
          channels = {msg: 'notmt. Code: 48'}
          if (res.currency) setCurrencyCredomatic(res.currency)
        } else if (res.msg === 'GREENPAY') {
          setMonedasGreenpay(res.monedasDisponibles)
          channels = {msg: 'greenpay. Code: 49'}
        } else if (res.msg === 'No credenciales') {
          channels = {msg: 'Servicio en mantenimiento. Code: 50'}
        } else {
          channels = res
        }

        if (channels && !channels.hasOwnProperty('msg')) {
          channels.forEach(c => {
            if (c.currencies === trayecto.primaryMoney.alphabeticCode) {
              canalSi = true;
            }
          })
          setDisabledCanal(!canalSi)
        } else if (channels && channels.msg && channels.msg.includes('greenpay') && trayecto) {
          let moneda = trayecto.primaryMoney.alphabeticCode
          if (monedasGreenpay.includes(moneda)) {
            canalSi = true;
          }
          setDisabledCanal(!canalSi)
        } else if (currencyCredomatic && trayecto) {
          if (currencyCredomatic !== trayecto.primaryMoney.alphabeticCode) {
            setDisabledCanal(true)
          }
        }
        setChannels(channels);

      }).catch(e => {
        Sentry.captureException(new Error("CollaboratorStretchPage - getChannels"));
        setChannels({msg: 'error. Code: 51'})
      })
    }
  }, [trayecto])

  // efecto para poner disabledDays
  useEffect(() => {
    if (trayecto) {
      let auxDD: Object[] = [];
      if (trayecto.disabledDays) {
        trayecto.disabledDays.forEach((disabledDay) => {
          let str = '{ "title": "No Disponible", ';
          const date = new Date(disabledDay.year, disabledDay.month, disabledDay.day);
          str += '"date": "' + Moment(date).format("YYYY-MM-DD") + '", ';
          str += '"constraint": "noDisponible", ';
          str += '"backgroundColor": "#ff9f89", '
          str += '"display": "background"';
          str += '}';
          let strjs = JSON.parse(str);
          auxDD.push(strjs);
        });

        setDD(auxDD);
      }
    }
  }, [trayecto])

  // efecto para poner los precios en función de ida o ida-vuelta
  useEffect(() => {
    if (trayecto) {
      description.current = trayecto.name;

      if (prices) {
        setDisabled(prices.length === 0);
        let auxVis: number[] = [];
        let auxPrice: number[] = [];
        let auxPriceNet: number[] = [];
        let auxNames: string[] = [];
        const indices: number[] = []
        prices.forEach((precio) => {
          auxVis.push(0);
          auxPrice.push(0.00);
          auxPriceNet.push(0.00);
          auxNames.push(precio.passengerCategory.name);
          indices.push(precio.passengerCategory.id)
          if (precio.passengerCategory.minToReserve && precio.price > 0) setMinimumPassengerReach(false)
        });

        let auxQuantityExtras: number[] = []
        let auxPriceExtras: number[] = []
        let auxNamesExtras: string[] = []
        let auxTaxExtras: number[] = []
        trayecto.extras.forEach((extra) => {
          auxQuantityExtras.push(0)
          auxPriceExtras.push(0.00)
          auxTaxExtras.push(extra.tax.value)
          auxNamesExtras.push(extra.name)
        })

        setVisitas({
          names: auxNames,
          indices,
          vis: auxVis,
          price: auxPrice,
          priceNet: auxPriceNet,
          base: 0.00,
          total: 0.00,
          totalNet: 0.00,
          totalDiscount: 0.00,
          extras: [], extrasEdit: {
            names: auxNamesExtras,
            prices: auxPriceExtras,
            taxes: auxTaxExtras,
            quantityExtras: auxQuantityExtras
          },
          disabledButtons: false,
          discount: visitas.discount
        });

        setTotalSeats(0);
      } else {
        setDisabled(true);
      }
    }

  }, [prices /*trayecto, channels, quantityExtras*/]);

  // efecto para poner las horas
  useEffect(() => {
    if (trayecto) {
      const horas = trayecto.horasIda;
      if (horas && horas.length !== 0 && !selectedHour) {
        setSelectedHour(horas[0])
        setHorasDisponibles(horas)
      }
    }
  }, [trayecto])

  // efecto para poner precio en función de si cambia a ida o a ida-vuelta
  useEffect(() => {
    if (trayecto) {
      if (!trayectoIdaVuelta) {// El trayecto es solo ida
        if (trayecto.prices) {
          trayecto.prices.sort((a, b) => {
            return a.passengerCategory.order - b.passengerCategory.order
          })
          setPrices(trayecto.prices)
        }
      } else {
        if (trayecto.vueltaPrices) {
          trayecto.vueltaPrices.sort((a, b) => {
            return a.passengerCategory.order - b.passengerCategory.order
          })
          setPrices(trayecto.vueltaPrices)
        }
      }
    }
  }, [trayectoIdaVuelta])

  const clickDateHandler = (arg: {
    date: Date
    dateStr: string
    allDay: boolean
    dayEl: HTMLElement
    jsEvent: MouseEvent
    view: ViewApi
  }) => {

    if (!trayecto) {
      return
    }

    // Solamente el día clicado en cada momento será el día seleccionado con fondo azul
    const days = document.querySelectorAll(".color-selected");
    days.forEach(function (day) {
      day.classList.remove("color-selected")
    })
    
    arg.dayEl.classList.add("color-selected")
    const date = arg.date
    let pertenece: boolean = false

    const dataMaxBeforeReserve: IMaxBeforeReserve = {
      date,
      isTramoHora: trayecto.tramoHora,
      valueMaxBeforeReserve: trayecto.valueMaxBeforeReserve,
      typeMaxBeforeReserve: trayecto.typeMaxBeforeReserve
    }
    let resultHorasIdaAvailable: IHora[] = horasIdaAvailableBefore(trayecto.horasIda, dataMaxBeforeReserve)

    let _selectedHour = selectedHour
    if (trayecto.disabledDays) {
      // miro si el día clicado está en deshabilitados
      const position = trayecto.disabledDays.findIndex(disabledDay =>
        disabledDay.day === date.getDate() && disabledDay.month === date.getMonth() && disabledDay.year === date.getFullYear()
      )

      if (position === -1) { // no está en disabledDays, el día está habilitado
        if (trayecto.tramoHora && trayecto.horasIda && resultHorasIdaAvailable.length > 0) {
          setHorasDisponibles(resultHorasIdaAvailable)
          if (selectedHour) {
            const finded = resultHorasIdaAvailable.find(available => selectedHour.hours === available.hours && selectedHour.minutes === available.minutes)
            if (!finded) {
              _selectedHour = resultHorasIdaAvailable[0]
            }
          }
        }
        if (!selectedHour && trayecto.horasIda && resultHorasIdaAvailable.length > 0) {
          _selectedHour = resultHorasIdaAvailable[0]
        }
      } else { // el día está en disabledDays. puede ocurrir varias condiciones
        // busco el día deshabilitado
        const dayDisabled = trayecto.disabledDays[position]

        // tramoHora: si hours.length === 0 all dia deshabilitado, si hours.length >0 tiene horas deshabilitadas
        if (!trayecto.tramoHora) {// no es tramoHora, el día está deshabilitado
          pertenece = true
        } else {
          if (dayDisabled.hours.length === 0) { // todas las horas deshabilitadas
            setHorasDisponibles([])
            pertenece = true
          } else {
            const horasIdaReduced = horasIdaAvailable(resultHorasIdaAvailable || [], dayDisabled)
            // setHorasDisponibles(horasIdaReduced)
            if (horasIdaReduced.length === 0) {
              pertenece = true
            } else {
              let incluido = []
              if (selectedHour) {
                incluido = horasIdaReduced.filter(hora => hora.id === selectedHour.id)
              }
              if (!selectedHour || incluido.length === 0) {
                _selectedHour = horasIdaReduced[0]
              }
            }
            resultHorasIdaAvailable = horasIdaReduced
          }
        }
      }
    } else {
      if (trayecto.tramoHora && trayecto.horasIda && resultHorasIdaAvailable.length > 0) {
        setHorasDisponibles(resultHorasIdaAvailable)
        if (!selectedHour) {
          _selectedHour = resultHorasIdaAvailable[0]
        }
      }
    }

    const {typeMaxToReserve, valueMaxToReserve, dateMaxToReserve, typeMaxBeforeReserve, valueMaxBeforeReserve} = trayecto

    // Miro si tiene fecha o dias máximos para reservar
    const maxToReserveResult = DisabledDay.maxToReserve(
      date, typeMaxToReserve, valueMaxToReserve, dateMaxToReserve
    )


    // Miro si tiene dateBeforeMax

    let hoursMinutes = _selectedHour ?? resultHorasIdaAvailable[0]
    const finded = resultHorasIdaAvailable.find(available => hoursMinutes.hours === available.hours && hoursMinutes.minutes === available.minutes)
    if (!finded) {
      hoursMinutes = resultHorasIdaAvailable[0]
    } else {
      hoursMinutes = finded
    }

    const maxBeforeReserveResult = DisabledDay.maxBeforeReserve(
      date, typeMaxBeforeReserve, valueMaxBeforeReserve, trayecto.tramoHora, hoursMinutes
    )


    let todaysDate = new Date()
    todaysDate.setHours(0, 0, 0, 0)

    let dateParsed = new Date(date)
    const beforeToday = dateParsed < todaysDate

    if (!pertenece && !beforeToday && !maxToReserveResult && !maxBeforeReserveResult) {
      //"2020-08-04T14:58:01.415Z"
      setHorasDisponibles(resultHorasIdaAvailable)
      setSelectedHour(_selectedHour)
      setDateClicked(Moment(date).format())
    } else {
      setOpenDatePopup(true)
    }
  }

  const closeClickDateHandler = () => {
    setOpenDatePopup(false);
  };

  const addExtras = (event: any) => {
    const index = event.currentTarget.value
    if (trayecto && index) {
      const {auxVis, limite} = addExtraGlobal(visitas, trayecto, index)
      if (auxVis !== null) setVisitas(auxVis)
    }
  }

  const quitarExtras = (event: any) => {
    const index = event.currentTarget.value
    if (trayecto && index) {
      const {auxVis, limite} = quitarExtraGlobal(visitas, trayecto, index)
      if (auxVis !== null) setVisitas(auxVis)
    }
  }

  const addVisitantePrices = (event: any) => {
    const index = event.currentTarget.value;
    if (trayecto && index) {
      const {auxVis, limite} = addVisitanteGlobal(visitas, trayecto, index, prices);
      prices.forEach(price => {
        if (price.passengerCategory.minToReserve && price.price > 0) {
          const name = price.passengerCategory.name
          const index = auxVis.names.indexOf(name)
          const nReserve = auxVis.vis[index]
          if (nReserve >= price.passengerCategory.minToReserve) {
            setMinimumPassengerReach(true)
          } else {
            setMinimumPassengerReach(false)
          }
        }
      })
      if (auxVis !== null) setVisitas(auxVis);
      setLimiteCapacidad(limite)
      let auxTot = totalSeats;
      auxTot++;
      setTotalSeats(auxTot);
    }
  };
  /*const addVisitanteVueltaPrices = (event: any) => {
      const index = event.currentTarget.value;
      if (trayecto && index) {
          const {auxVis} = addVisitanteGlobal(visitas, trayecto, index, trayecto.vueltaPrices);           
          if (auxVis !== null) setVisitas(auxVis);
          let auxTot = totalSeats;
          auxTot++;
          setTotalSeats(auxTot);
      }
  };*/

  const quitarVisitantePrices = (event: any) => {
    const index = event.currentTarget.value;
    if (trayecto && index) {
      const auxVis = quitarVisitanteGlobal(visitas, trayecto, index, prices);
      prices.forEach(price => {
        if (price.passengerCategory.minToReserve && price.price > 0) {
          const name = price.passengerCategory.name
          const index = auxVis.names.indexOf(name)
          const nReserve = auxVis.vis[index]
          if (nReserve >= price.passengerCategory.minToReserve) {
            setMinimumPassengerReach(true)
          } else {
            setMinimumPassengerReach(false)
          }
        }
      })
      if (auxVis !== null) setVisitas(auxVis);
      setLimiteCapacidad(false)
      let auxTot = totalSeats - 1;
      setTotalSeats(auxTot);
    }
  };

  const handleChangeOpcionVuelta = (event: any) => {
    setTrayectoIdaVuelta(!trayectoIdaVuelta)
  }

  const handleSentidoTrayecto = (e) => {
    setSentidoOrigen(!sentidoOrigen)
  }

  /*const quitarVisitanteVueltaPrices = (event: any) => {
      const index = event.currentTarget.value;
      if (trayecto && index) {
          const auxVis = quitarVisitanteGlobal(visitas, trayecto, index, trayecto.vueltaPrices);
          if (auxVis !== null) setVisitas(auxVis);

          let auxTot = totalSeats - 1;
          setTotalSeats(auxTot);
      }
  };*/

  const horaChangeHandler = (event: any) => {

    const id = event.currentTarget.value
    if (id !== undefined && trayecto) {
      let hora = trayecto.horasIda.filter(hora => hora.id === +id)
      setSelectedHour(hora[0])
    }
    /*const index = event.currentTarget.value;
    if (index !== undefined && trayecto) {
        setSelectedHour(trayecto.horasIda[index]);
    } */
  };

  const addSeatCallback = async ({row, number, id}: ISeat, addCb: AddCallBackType) => {
    setLoading(true);
    await new Promise(resolve => setTimeout(resolve, 1500));
    const newTooltip = 'Seleccionado';
    addCb(row, number, id, newTooltip);
    setLoading(false);
  };

  const removeSeatCallback = async ({row, number, id}: ISeat, removeCb: RemoveCallBackType) => {
    setLoading(true);
    await new Promise(resolve => setTimeout(resolve, 1500));
    const newTooltip = null;
    removeCb(row, number, newTooltip);
    setLoading(false);
  };

  /*const trayectoIdaVueltaClickHandler = (event: any) => {
      setTrayectoIdaVuelta(true);
      setSoloIdaClassName(UNCHECKED_CLASSNAME);
      setIdaVueltaClassName(CHECKED_CLASSNAME);
  }

  const trayectoSoloIdaClickHandler = (event: any) => {
      setTrayectoIdaVuelta(false);
      setSoloIdaClassName(CHECKED_CLASSNAME);
      setIdaVueltaClassName(UNCHECKED_CLASSNAME);
  }*/

  const addCart = () => {
    if (!dateClicked) {
      setOpenDatePopup(true);
    } else {
      setAdding(true)
      amount = (+visitas.total.toFixed(2));
      // TODO
      if (trayecto) {
        let tokenTicket;
        try {
          tokenTicket = localStorage.getItem('tokenTicket');
          if (!tokenTicket) {
            tokenTicket = generateTokenticket(20);
            localStorage.setItem('tokenTicket', tokenTicket);
          }
        } catch (e) {
          if (publicToken) {
            history.push(`/${publicToken}/cookies`)
          }
        }

        mountPayloadStretch(
          {
            stretch: trayecto,
            selectedDate: dateClicked,
            selectedHour,
            visitas,
            lengthsName: 'collaboratorStretch',
            publicToken,
            trayectoIdaVuelta,
            sentidoOrigen,
            prices,
            channels,
            afiliate: a
          }
        ).then((ticket: any) => {
          ticket.type = TypeTicket.CollaboratorStretch
          ticket.operator_id = trayecto.operator_id
          ticket.additional = additional
          ticket.visitas = visitas
          ticket.prices = prices
          ticket.tax = trayecto.tax
          ticket.operatorName = trayecto.operatorName

          let uuid;
          try {
            uuid = localStorage.getItem('tokenTicket');
          } catch (e) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Es necesario activar las cookies de terceros para poder realizar la reserva.',
              footer: `Ayuda: En Chrome dirígete a chrome://settings/cookies para permitirlo.`
            })
          }

          addCollaboratorStretchToCart(ticket, publicToken).then((res) => {
            setAdding(false)
            let message: string = "";
            let info = false;

            // manejo cuando la respuesta no trae el código ya que es la propia respuesta el código
            if (!res.hasOwnProperty('code')) {
              res = {code: res}
            }
            switch (res.code) {
              case 200:
                if (!uuid) {
                  try {
                    localStorage.setItem('tokenTicket', res.payload.token)
                  } catch (e) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Error',
                      text: 'Es necesario activar las cookies de terceros para poder realizar la reserva.',
                      footer: `Ayuda: En Chrome dirígete a chrome://settings/cookies para permitirlo.`
                    })
                  }
                }
                history.push(a ? `/${publicToken}/cart/?at=${a.publicToken}` : `/${publicToken}/cart/`)
                break;
              case 400:
                message = t("tokenNoExists"); // El token no existe.
                break
              case 404:
                message = t("stretchInCart") //'Ya tiene agregada esa experiencia al carrito, si quiere modificarla tendrá que eliminarla primero del carrito.'
                break
              case 410:
                message = t("errorCreateTicket") //"Error al generar el ticket."
                break
              case 412:
                message = t("obtainError") //"Hemos obtenido un error."
                break
              case 413:
                info = true;
                message = `${ticket.tramoHora ? t("noDisponibility") : 'En este momento no tenemos disponibilidad para el día seleccionado.'}  ${t("thenDisponibility")}`  //t("notEnoughCapacity") //"No hay aforo suficiente."
                break
              case 414:
                info = true;
                message = t("serviceNotAvailable") //"El servicio que quiere reservar no está disponible."
                break
              case 415:
                info = true;
                message = t("dateDisabled") //"El servicio está deshabilitado para esa fecha."
                break
              case 416:
                info = true;
                message = t("hourDisabled") //"El servicio está deshabilitado para esa hora."
                break
              case 433:
                try {
                  localStorage.removeItem('tokenTicket')
                  addCart()
                } catch (e) {
                  console.log(e)
                }
                break
              case 500:
                message = t("serverError") //"Error interno del servidor."
                break
              default:
                message = t("Sorry, we got an unexpected error.") //"Lo siento, hemos obtenido un error inesperado."
                break
            }
            if (message !== "") {
              if (info) {
                modalInfo(message, t)
              } else {
                modalError(message)
              }
            }
            // Comprobamos si es un número
            /*if ((/^[0-9]*$/).test(res)) {
                const statusCode: number = +res;
                let message: string = "";
                switch (statusCode) {
                    case 400:
                        message = "El token no existe.";
                        break;
                    case 404:
                        message = 'Ya tiene agregada esa experiencia al carrito, si quiere modificarla tendrá que eliminarla primero del carrito.';
                        break;
                    case 410:
                        message = "Error al generar el ticket.";
                        break;
                    case 412:
                        message = "Hemos obtenido un error.";
                        break;
                    case 413:
                        message = "No hay aforo suficiente.";
                        break;
                    case 414:
                        message = "El servicio que quiere reservar no está disponible.";
                        break;
                    case 415:
                        message = "El servicio está deshabilitado para esa fecha.";
                        break;
                    case 416:
                        message = "El servicio está deshabilitado para esa hora.";
                        break;
                    case 500:
                        message = "Error interno del servidor.";
                        break;
                    default:
                        message = "Lo siento, hemos obtenido un error inesperado.";
                        break;
                }
                Sentry.captureException(new Error(`CollaboratorStretch - ${message}`));
                history.push(`${publicToken}/error/${message}/`);
            } else { // En caso de no ser un número, es la url
                if (!uuid) {
                    localStorage.setItem('tokenTicket', res.uuid);
                }
                history.push({
                    pathname: `/${publicToken}/cart/`,
                    search: publicToken
                })
            }*/
          }).catch(e => {
            setAdding(false)
            Sentry.captureException(new Error(`CollaboratorStretch - Añadir`));
            modalError(t("errorAddCart"))
          });
        });

        const additional = {
          stretchName: trayecto.name,
          stretchDescription: trayecto.description,
          tokenticket: tokenTicket,
          description: description
        }
      }
    }
  };

  const handleApplyDiscount = (discount: IDiscount) => {
    const auxVis = addDiscount({vis: visitas, discount})
    setVisitas(auxVis)
  }
  
  let disableAddCart = true
  if (trayecto) {
    disableAddCart = !visOK(visitas) || dateClicked === ''
  }

  const images = []
  if (trayecto && trayecto.dataweb) {

    for (let index in trayecto.dataweb) {
      if (index.startsWith('image')) {
        const temp = {
          original: trayecto.dataweb[index].replace('http://', 'https://'),
          thumbnail: trayecto.dataweb[index].replace('http://', 'https://')
        }
        images.push(temp)
      }
    }
    /*for (let i=1; i<=15; i++) {
        if (trayecto.dataweb[`image${i}`]) {
            if(trayecto.dataweb[`image${i}`] !== " "){
                const temp = {
                    original: trayecto.dataweb[`image${i}`].replace('http://','https://'),
                    thumbnail: trayecto.dataweb[`image${i}`].replace('http://','https://')
                }
                images.push(temp)
            }               
        }
    }*/
  }
  if (images.length === 0) {
    images.push(
      {
        original: "https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg",
        thumbnail: "https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg"
      })
  }

  const _total = visitas.total - visitas.totalDiscount
  
  return (
    <div className="page-wrapper animated fadeIn">
      <HeaderView publicToken={publicToken}/>
      <section className="page-header tour-two tour-list destinations-details">
        <div className="container">
          <div className="row">

            {
              ((!trayecto && trayecto !== null) || !channels) &&
              <div style={{top: '50%', left: '50%,', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Spinner color="primary" style={{width: 100, height: 100}}></Spinner>
              </div>
            }

            {
              trayecto && (trayecto.disableWeb || trayecto.disabled_at || trayecto.operationLine.disabled_at) &&
              <BodyError message={t("serviceNotAvailable")} listParagraph={[t("thanksSorry")]}/>
            }

            {
              channels && channels.hasOwnProperty('msg') && channels.msg.includes('5') &&
              <BodyError message={channels.msg ? channels.msg : 'error'}
                         listParagraph={[t("requestChannelsErrormsg"), t("thanksSorry")]}/>
            }

            {
              trayecto && !trayecto.disableWeb && !trayecto.disabled_at && !trayecto.operationLine.disabled_at && channels && ((channels.hasOwnProperty('msg') && !channels.msg.includes('5')) || !channels.hasOwnProperty('msg')) &&
              <>
                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-8">
                  {
                    disabledCanal && channels.msg && !channels.msg.includes('greenpay') && !currencyCredomatic &&
                    <div style={{backgroundColor: 'red', color: 'white'}}>
                      {t("noMoneyTMT")}
                    </div>
                  }
                  {
                    disabledCanal && channels.msg && channels.msg.includes('greenpay') && !currencyCredomatic &&
                    <div style={{backgroundColor: 'red', color: 'white'}}>
                      {t("noMoneyGreenpay")}
                    </div>
                  }
                  {
                    disabledCanal && currencyCredomatic && currencyCredomatic !== trayecto.primaryMoney.alphabeticCode &&
                    <div style={{backgroundColor: 'red', color: 'white'}}>
                      {t("noMoneyCredomatic")}
                    </div>
                  }
                  <div className="destinations-details__content">
                    <h3 className="destinations-details__title">{trayecto.name}</h3>
                    <Carousel autoPlay infiniteLoop showStatus={false}>
                      {
                        images.filter(img => img.original !== '').map(image => {
                          return (
                            <div key={image.original}>
                              <img
                                src={image.original.replace("w_250,h_200,c_fill", "c_fill").replace("w_450,h_350", "")}
                                alt=''/>
                            </div>
                          )
                        })
                      }
                    </Carousel>

                    <LanguageButtons
                      languages={languages}
                      languageSelected={language}
                      onClick={(lang) => {
                        localStorage.setItem('idioma', lang)
                        setLanguage(lang)
                      }}
                    />
                    
                    {
                      <InfoWeb 
                        trayecto={trayecto} 
                        orden={1} 
                        publicToken={publicToken}
                        language={language}
                      />
                    }
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-5 col-lg-4">
                  <div className="tour-sidebar">
                    <div className="tour-sidebar__featured">
                      <CtaView 
                        t={t}
                        href={trayecto && trayecto.dataweb && trayecto.dataweb.array && trayecto.dataweb.array.tags && trayecto.dataweb.array.tags[language] &&  trayecto.dataweb.array.tags[language].href}
                        mainTag={trayecto?.dataweb?.destination?.value?.[language] || trayecto?.dataweb?.destination?.name }
                      />
                      <div className="fechas">
                        <CalendarAvailable
                          disabledDays={trayecto.disabledDays}
                          horasIda={trayecto.horasIda}
                          clickDateHandler={clickDateHandler}
                          idioma={idioma}
                          dateMaxToReserve={trayecto.dateMaxToReserve}
                          valueMaxToReserve={trayecto.valueMaxToReserve}
                          typeMaxToReserve={trayecto.typeMaxToReserve}
                          typeMaxBeforeReserve={trayecto.typeMaxBeforeReserve}
                          valueMaxBeforeReserve={trayecto.valueMaxBeforeReserve}
                          stretch={trayecto}
                          selectedHourMinutes={selectedHour}
                        />

                        {
                          dateClicked !== "" &&
                          <p className="lead">
                            <b>{t("dateSelected")}:</b> {Moment(dateClicked).format("DD/MM/YYYY")}
                          </p>
                        }
                      </div>
                      <DialogNoDate openDatePopup={openDatePopup} closeClickDateHandler={closeClickDateHandler} t={t}/>

                      {
                        dateClicked && trayecto && trayecto.tramoHora && horasDisponibles && horasDisponibles.length > 0 &&
                        <div className="horas">
                          <h3>{t("departureTime")}</h3>


                          <select value={selectedHour.id} className="custom-select" id="hourSelect"
                                  onChange={horaChangeHandler}>
                            {
                              horasDisponibles.map((hora) => {
                                const horaString = ((hora.hours.toString().length === 1) ? "0" : "") + hora.hours.toString() + hora.minutes.toString();
                                const horaSalida = Moment(horaString, "HHmm").format('HH:mm');
                                return (
                                  <option value={hora.id} key={hora.id}>
                                    {horaSalida}
                                  </option>
                                );
                              })
                            }
                          </select>
                        </div>
                      }

                      {
                        trayecto.vuelta &&
                        <div className="opciones">
                          <br/>
                          <div className="row row-cols-auto text-left align-items-center">
                            <SelectIdaVuelta prices={trayecto.prices} vueltaPrices={trayecto.vueltaPrices}
                                             totalSeats={totalSeats} handleChangeOpcionVuelta={handleChangeOpcionVuelta}
                                             trayectoIdaVuelta={trayectoIdaVuelta}/>
                            {
                              trayecto.origin && trayecto.destination &&
                              <OrigenDestino sentidoOrigen={sentidoOrigen} trayecto={trayecto}
                                             handleSentidoTrayecto={handleSentidoTrayecto}/>
                            }
                          </div>
                        </div>
                      }
                      {
                        trayecto.hoponHopoff &&
                        <div className="opciones">
                          <div>
                            <p className="outline-primary">HoponHopff</p>
                          </div>
                        </div>
                      }
                      {
                        disabled &&
                        <div>
                          {t("experienceNotAvailablemsg")}
                        </div>
                      }
                      <CodeDiscountView
                        loading={loadingCouponCode}
                        code={couponMessageCode}
                        message={couponMessage}
                        onApply={(value) => {
                          console.log('value: ', value)
                          setLoadintCouponCode(true)
                          applyCoupon({stretch_id: 1, couponCode: value, token: publicToken})
                            .then(response => {
                              if (response.result === 'ok') {
                                console.log('response: ', response)
                                const responseDiscountCode = response.discountCode
                                const message = `${responseDiscountCode.code} ${t('appliedSuccessfully')}`
                                setCouponMessage(message)
                                setCouponMessageCode(response.code)
                                const discount: IDiscount = {
                                  discountCode: responseDiscountCode.code,
                                  typeDiscount: responseDiscountCode.typeDiscount,
                                  value: responseDiscountCode.value
                                }
                                handleApplyDiscount(discount)
                              } else {
                                const message = response.code === 404 ? T('codeNotFound') :response.message
                                setCouponMessage(message)
                                setCouponMessageCode(response.code)
                              }
                            })
                            .catch(reason => {

                            })
                            .finally(() => setLoadintCouponCode(false))
                        }}
                        onReset={(() => {
                          setCouponMessage(null)
                          const tempVisitas = resetDiscount({vis: visitas})
                          setVisitas(tempVisitas)
                        })}
                      />
                      <PricesView
                        prices={prices}
                        money={trayecto.primaryMoney.alphabeticCode}
                        tax={trayecto.tax}
                        passengerCategories={passengerCategories}
                        visitas={visitas}
                        addVisitantePrices={addVisitantePrices}
                        quitarVisitantePrices={quitarVisitantePrices}
                        //addVisitantePrices={addPassenger}
                        //quitarVisitantePrices={removePassenger}
                        limiteCapacidad={limiteCapacidad}
                        publicToken={publicToken}
                        isCollaboratorStretch
                        minimumPassengerReach={minimumPassengerReach}
                      />
                      {
                        trayecto.extras && trayecto.extras.length !== 0 &&
                        <Extras money={trayecto.primaryMoney.alphabeticCode} visitas={visitas} trayecto={trayecto}
                                addExtras={addExtras} quitarExtras={quitarExtras}/>
                      }
                      {
                        trayecto.seats &&
                        <>
                          <SeatPicker
                            addSeatCallback={addSeatCallback}
                            removeSeatCallback={removeSeatCallback}
                            rows={trayecto.seats}
                            maxReservableSeats={totalSeats}
                            alpha
                            visible
                            selectedByDefault
                            loading={loading}
                            tooltipProps={{multiline: true}}
                          />
                        </>
                      }


                      <div className="book-form-totals">
                        {visitas.discount && visitas.total>0 && <p className='totalAlign'>
                          <b>SubTotal: </b> {visitas.total.toFixed(2)} {trayecto.primaryMoney.alphabeticCode}
                        </p>}
                        {visitas.discount && visitas.total>0 && <p className='totalAlign'>
                          <b>{T('discount')}: </b> {-visitas.totalDiscount.toFixed(2)} {trayecto.primaryMoney.alphabeticCode}
                        </p>}
                        <p className='totalAlign'>
                          <b>Total: </b> {_total.toFixed(2)} {trayecto.primaryMoney.alphabeticCode}
                        </p>
                      </div>

                      {
                        !adding &&
                        <button type="button"
                                disabled={disableAddCart || disabled || disabledCanal || !minimumPassengerReach}
                                className="btn-huge btn-wide btn-blue test-add-to-cart-action-enabled"
                                onClick={addCart}>
                          {t("addCart")}
                        </button>
                      }
                      {
                        adding &&
                        <button type="button" disabled={disableAddCart || disabled || disabledCanal}
                                className="btn-huge btn-wide btn-blue test-add-to-cart-action-enabled">
                          <Spinner/>
                        </button>
                      }
                      {
                        <InfoWeb trayecto={trayecto} orden={2} publicToken={publicToken}/>
                      }
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </section>
      <FooterView/>
    </div>
  );
}
