import './footer.css'

interface Props {
  hrefSrc: HrefSrc[]
}

export const Footer = (props: Props) => {
  const {hrefSrc} = props

  const _hrefSrc = hrefSrc || []

  return (
    <footer>
      <div className="logos">
        {_hrefSrc.map(hrefSrc => (
          <a
            key={hrefSrc.url}
            href={hrefSrc.href} target="_blank"
          >
            <img src={hrefSrc.url} alt={hrefSrc.alt}/>
          </a>
          ))}
      </div>
    </footer>
  )

}

interface HrefSrc {
  href: string
  url: string
  alt: string
}