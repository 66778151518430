import React from 'react';
import {SecureBagde} from '../../components/SecureBagde'

export default function CartTotal({ totalAmount, money, t, totalBookingFee }) {
    const subTotal = totalAmount - totalBookingFee
    return <div>
        <div className="book-form-totals book-form-totals2 has-secure-badge has-totals">
            <div className="table valign-middle full-width">
                <div className="tr">
                    <div className="td">
                        <SecureBagde t={t}/>
                    </div>
                    <div>
                        {totalBookingFee>0 && <Line large name={t('subtotal')} total={subTotal} money={money}/>}
                        {totalBookingFee>0 && 
                            <Line large name={t('bookingFee')} total={totalBookingFee} money={money}/>
                        }
                        {totalBookingFee>0 && 
                          <hr className="text-right p-0 m-0"/>
                        }
                        <div className="td"><div className="text-right">Total: {totalAmount.toFixed(2)} {money}{/*money === 'EUR' ? '€' : '$'*/}</div> </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>;
}

const Line = ({name, total, money, large}: {name: string, total: number, money: string, large?: boolean}) => {
   return (
     <div className="td">
         <div className={`text-right ${large? 'text-large' : ''}`}>{name}: {total.toFixed(2)} {money}{/*money === 'EUR' ? '€' : '$'*/}</div>
     </div>
   )
}